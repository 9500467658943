<template>
  <div @click="fetchOptions()" class="label-field">
    <v-select
      :options="filteredOptions"
      :resetOnOptionsChange="true"
      label="Text"
      name="labelEditor"
      v-model="selectedLabel"
    />

    <field-label-selector-label
      v-for="label in labelList"
      :key="label.description"
      :background-color="label.Color"
      :text-color="label.TextColor"
      :description="label.description"
      :readonly="false"
      @remove="removeLabel(label.key)"
    />
    <input hidden vue-input name="Label" v-model="stringifiedLabels" />
  </div>
</template>

<script>
import FieldLabelSelectorLabel from "./FieldLabelSelectorLabel.vue";

import {getComboboxItems} from "@/services/mainRadComboBox";
import processFormDataChange from "@/functions/window/processFormDataChange";
import vSelect from "vue-select";

export default {
  components: {
    FieldLabelSelectorLabel,
    vSelect,
  },
  props: {
    field: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      labels: {},
      labelList: [],

      options: [],

      optionsRetrieved: false,
      selectedLabel: {},
      stringifiedLabels: "",
    };
  },
  computed: {
    filteredOptions() {
      if (!this.options.length) return [];
      return this.options.filter(
        (option) => !this.rawLabelList.includes(option.Text),
      );
    },
    rawLabelList() {
      return this.labelList.map((label) => label.description);
    },
  },
  created() {
    if (!this.field.Value) return;
    this.labels = JSON.parse(this.field.Value ?? "{}");

    if (this.labels === null) return;

    this.labelList = Object.keys(this.labels).map((key) =>
      Object({...this.labels[key], key: key}),
    );
    this.stringifiedLabels = JSON.stringify(this.labels);
  },
  watch: {
    selectedLabel(val) {
      if (!val?.Text || val?.Text === null) return;
      this.rawLabelList.push(val.Text);

      this.labelList.push({
        Color: val.Attributes.Color,
        TextColor: val.Attributes.TextColor,
        description: val.Text,
        key: val.Value,
      });

      this.labels[val.Value] = {
        Color: val.Attributes.Color,
        TextColor: val.Attributes.TextColor,
        description: val.Text,
        key: val.Value,
      };

      this.stringifiedLabels = JSON.stringify(this.labels);

      processFormDataChange({
        value: this.stringifiedLabels,
        fieldName: "Label",
      });
      this.selectedLabel = {};
    },
  },

  methods: {
    async fetchOptions() {
      if (this.optionsRetrieved === true) return;

      const subject =
        global.session.activeWindow.sub.window.output.Data.Subject ??
        "Rental.Item";

      this.optionsRetrieved = true;
      const options = await getComboboxItems({
        columnName: "LabelID",
        subject,
        tableName: "Core.Label",
      });

      this.options = options;
    },
    removeLabel(key) {
      delete this.labels[key];
      this.labelList = this.labelList.filter((label) => label.key != key);

      this.stringifiedLabels = JSON.stringify(this.labels);
    },
  },
};
</script>
