export default function processFormDataChange({value, fieldName}) {
  // eslint-disable-next-line no-undef
  const window = session.activeWindow.sub.window ?? session.active;
  const row = 0;

  const index = window.output.Table.Columns.map((col, index) =>
    col.Name === fieldName ? index : null,
  )
    .filter((index) => index !== null)
    .pop();

  window.processChange(row, index, value);
}
